<template>
  <div class='decision'>
    <el-row :gutter="10">
      <el-col :span="12">
        <div id="warning">
        </div>
      </el-col>
      <el-col :span="12">
        <div id="dispose">
        </div>
      </el-col>
    </el-row>
    <div class="count">
      <el-button-group>
        <el-button type="primary" v-for="item in btnList" :key="item.id" @click="changebtn(item.id)" :class="item.id === num?'active':''">
          {{item.name}}
        </el-button>
      </el-button-group>
      <el-date-picker size="small" v-model="value1" type="daterange" start-placeholder="开始日期" end-placeholder="结束日期" style="margin-left:20px">
      </el-date-picker>
      <el-row :gutter="20">
        <el-col :span="8" v-for="item in countList" :key="item.id">
          <div :id="item.classname" @mouseover="over(item)" @mouseout="out(item)" :style="{borderColor:item.color}">
            <p>
              <img :src="item.icon" alt="">
              <span>{{item.name}}</span>
            </p>
            <div>
              <p>累计报警次数<span>7次</span></p>
              <p>累计处理次数<span>7次</span></p>
              <p>报警最多单位<span>XXX单位</span></p>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      btnList: [
        { id: 0, name: '近七天' },
        { id: 1, name: '近一月' },
        { id: 2, name: '近半年' },
      ],
      countList: [
        { id: 0, name: '酒店', icon: require('../../assets/sta/hotel.png'), classname: 'hotel', bg: require('../../assets/sta/hotel_bg.png'), hover: require('../../assets/sta/hotel_hover.png'), color: '#f6b24b' },
        { id: 1, name: '影院', icon: require('../../assets/sta/cinema.png'), classname: 'cinema', bg: require('../../assets/sta/cinema_bg.png'), hover: require('../../assets/sta/cinema_hover.png'), color: '#12c3c9' },
        { id: 2, name: '医院', icon: require('../../assets/sta/hospital.png'), classname: 'hospital', bg: require('../../assets/sta/hospital_bg.png'), hover: require('../../assets/sta/hospital_hover.png'), color: '#9333d7' },
        { id: 3, name: '学校', icon: require('../../assets/sta/school.png'), classname: 'school', bg: require('../../assets/sta/school_bg.png'), hover: require('../../assets/sta/school_hover.png'), color: '#12c3c9' },
        { id: 4, name: '辐射', icon: require('../../assets/sta/radiation.png'), classname: 'radiation', bg: require('../../assets/sta/radiation_bg.png'), hover: require('../../assets/sta/radiation_hover.png'), color: '#e77b7a' },
        { id: 5, name: '口腔', icon: require('../../assets/sta/tooth.png'), classname: 'tooth', bg: require('../../assets/sta/tooth_bg.png'), hover: require('../../assets/sta/tooth_hover.png'), color: '#2c7afe' }
      ],
      value1: '',
      num: null,
      navNum: null
    };
  },
  created () {

  },
  mounted () {
    this.drawWarning()
    this.drawDispose()
  },
  methods: {
    // 按钮切换时间
    changebtn (val) {
      this.num = val
    },
    // 报警总数对比
    drawWarning () {
      const chart = this.$echarts.init(document.getElementById('warning'))
      const data = [];
      for (let i = 0; i < 5; ++i) {
        data.push(Math.round(Math.random() * 200));
      }
      let option = {
        title: {
          text: '各场景报警总数对比',
          x: 'center',
          // y: 'center',
          textStyle: {
            fontSize: '28',
            fontWeight: '400',
            fontFamily: 'Source Han Sans CN',
            color: 'white',
          },
        },
        xAxis: {
          max: 'dataMax'
        },
        yAxis: {
          type: 'category',
          data: ['A', 'B', 'C', 'D', 'E'],
          inverse: true,
          animationDuration: 300,
          animationDurationUpdate: 300,
          max: 2 // only the largest 3 bars will be displayed
        },
        series: [
          {
            realtimeSort: true,
            name: 'X',
            type: 'bar',
            data: data,
            label: {
              show: true,
              position: 'right',
              valueAnimation: true
            }
          }
        ],
        legend: {
          show: false
        },
      }
      // console.log(chart, option)
      option && chart.setOption(option)
      window.addEventListener("resize", function () {
        chart.resize();
      })
    },
    // 处理总数对比
    drawDispose () {
      const chart = this.$echarts.init(document.getElementById('dispose'))
      const data = [];
      for (let i = 0; i < 5; ++i) {
        data.push(Math.round(Math.random() * 200));
      }
      let option = {
        title: {
          text: '各场景处理总数对比',
          x: 'center',
          // y: 'center',
          textStyle: {
            fontSize: '28',
            fontWeight: '400',
            fontFamily: 'Source Han Sans CN',
            color: 'white',
          },
        },
        xAxis: {
          max: 'dataMax'
        },
        yAxis: {
          type: 'category',
          data: ['A', 'B', 'C', 'D', 'E'],
          inverse: true,
          animationDuration: 300,
          animationDurationUpdate: 300,
          max: 2 // only the largest 3 bars will be displayed
        },
        series: [
          {
            realtimeSort: true,
            name: 'X',
            type: 'bar',
            data: data,
            label: {
              show: true,
              position: 'right',
              valueAnimation: true
            }
          }
        ],
        legend: {
          show: false
        },
      }
      // console.log(chart, option)
      option && chart.setOption(option)
      window.addEventListener("resize", function () {
        chart.resize();
      })
    },
    over (item) {
      document.getElementById(item.classname).style.backgroundImage = `url(${item.hover})`
    },
    out (item) {
      document.getElementById(item.classname).style.backgroundImage = `url(${item.bg})`
    }
  }
};
</script>

<style scoped lang="scss">
.decision {
  .el-row {
    height: 40vh;
    .el-col {
      height: 100%;
      div {
        width: 100%;
        height: 100%;
      }
    }
  }
  .count {
    padding: 0 0 20px 10px;
    .el-button--primary {
      background: none;
      border: 1px solid #30c6fe;
    }
    .active {
      background: #409eff;
    }
    .el-row {
      // padding-bottom: 20px;
      .el-col {
        margin-top: 20px;
        height: 20vh;
        > div {
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          display: flex;
          align-items: center;
          justify-content: space-around;
          border: 2px solid #fff;
          > p {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 50%;
            img {
              width: 30%;
            }
            span {
              font-size: 16px;
              color: #fff;
              margin-top: 10px;
            }
          }
          > div {
            color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            p {
              padding-right: 50px;
              font-size: 18px;
              display: flex;
              justify-content: space-between;
              // span{

              // }
            }
          }
        }
      }
    }
  }
}
</style>
